
<template>

  <div class="">
    <div class="flex gap-2">
      <label class="block text-sm font-medium text-gray-700 dark:text-white ">{{ heading }} <span v-if="requiredIndicator" class="text-red-600 text-sm">*</span></label>
      <BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip>
    </div>
    <input :disabled="disabled" :value="value" :class="inputClass()" @input="$emit('update:value', $event.target.value)"
      type="date"
      class=" block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark dark:text-white" />
    <div v-for="error in errors" :key="error.$uid">
      <p class="text-red-600 text-sm">{{ error.$message }}</p>
    </div>
  </div>
</template>

<script>
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";

export default {
  name: "DateInput",
  components: { BaseToolTip },
  props: {
    heading: String,
    toolText: String,
    value: String,
    errors: Object,
    version: {
      required: false,
      default: "1"
    },
    disabled: {
      required: false,
      default: false
    },
    tooltip: {
      required: false,
      default: ""
    },
    requiredIndicator:{
       type:Boolean,
       default:false
    },
  },
  methods: {
    inputClass() {
      if (this.version == "2") return "w-48 h-12"
      return "w-full mt-1"
    }
  }
}
</script>

<style scoped>

</style>
