<template>
    <FullModal :modalActive="modalActive" @close="$emit('close')">
        <MediaImageModalEdit v-if="editActive"
            :image="curImage"
            @close="onCancelEdit"

        />
        <MediaImageModalView v-else
                :images="images"
                :curIdx="curIdx"
                :modalActive="modalActive"
                :editMode="editMode"
                @close="$emit('close')"
                @edit="onEdit"
        />
    </FullModal>
</template>

<script>
import { ref, } from 'vue'
import MediaImageModalView from '@/components/widgets/mediaImageWidget/MediaImageModalView'
import MediaImageModalEdit from '@/components/widgets/mediaImageWidget/MediaImageModalEdit'
import FullModal from '@/components/widgets/FullModal'

export default {
    name: "MediaImageModalWindow",
    components: {FullModal, MediaImageModalView, MediaImageModalEdit },
    props: {
        images: {
            type: Array,
            required: true
        },
        curIdx: {
            type: Number,
            default: 0
        },
        modalActive: {
            type: Boolean
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        
        const editActive = ref(false)
        const curImage = ref(null)

        return { 
            editActive,
            curImage,
            onEdit(img) {
                curImage.value = img
                editActive.value = true
            },
            onCancelEdit() {
                curImage.value = {}
                editActive.value = false
            }
        }
    }
}
</script>

