<template>
  <Dialog :open="modalActive" @close="$emit('close')" class="relative z-40">
 <!-- The backdrop, rendered as a fixed sibling to the panel container -->
    <div class="fixed inset-0 bg-gray-200 " aria-hidden="true" />

    <!-- Full-screen container to center the panel -->
    <div class="fixed inset-0 flex w-full h-full items-center justify-center p-4">
      <!-- The actual dialog panel -->
      <DialogPanel class="w-full h-full rounded no-scroll-bar">
        <div class="absolute w-full z-50">
            <XIcon class="w-8 text-white  cursor-pointer" @click="$emit('close')" />
        </div>
        <slot />
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script>
import { Dialog, DialogPanel } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/solid/esm'
export default {
    name: "FullModal",
    components: { Dialog, DialogPanel, XIcon },
    props: {
        modalActive: Boolean
    }
}
</script>
