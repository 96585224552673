<template>
  <div id="modal">
    <transition name="modal-animation">
      <teleport to="#root">
        <div v-show="active" class="fixed z-10 h-full w-screen inset-0" :class="filters ? ' inset-y-1/3' :  ' inset-y-2/3'"
          aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="flex justify-center text-center">
            <!-- The div below can also set a color overlay -->
            <div @click="closeModal" class="fixed inset-0  transition-opacity"
            :class="backdrop ? 'bg-gray-800 bg-opacity-75' : ''"
              aria-hidden="true"></div>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden " aria-hidden="true">&#8203;</span>
            <div @click.stop class=" object-fit w-full
            rounded-lg transform
            transition-all bg-primary dark:bg-gray-800 dark:text-gray-300 m-2 shadow-2xl sm:w-2/3 max-w-lg">
              <div class="my-1">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </teleport>
    </transition>
  </div>
</template>

<script>
import { watch } from 'vue'

export default {
  name: "BottomSheet",
  components: {
  },
  props: {
    active: Boolean,
    backdrop: {
      required: false,
      default: true
    },
    // Is this being used for CategoryTable filters
    // Used to change the inset-y
    filters: {
      required: false,
      default: false,
    }
  },
  methods: {
    modalClasses() {
      if (this.version == "2") return "lg:w-4/5 xl:w-2/5"
      return "lg:w-2/5"
    },
    closeModal() {
      this.$emit('close')
    }
  },
  setup(props, { emit }) {
    function escPress() {
      emit('close')
    }

    watch(() => props.active, (modalOn) => {
      if (modalOn) {
        window.addEventListener('keydown', e => {
          if (e.key == "Escape") escPress()
        })
      } else {
        window.removeEventListener('keydown', e => {
          if (e.key == "Escape") escPress()
        })
      }
    })
    return {
      test() {

        console.log('ran');
      }
    }
  }
}
</script>

<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
</style>