<template>
        <div class="relative grid grid-rows-6 grid-cols-1 h-full w-full bg-white shadow">
            <div class="relative row-span-4 col-span-1 flex justify-center bg-black">
                <img :src="modalImg" class="object-cover"/>
            </div>
            <div class="row-span-2 col-span-1 grid grid-cols-3 gap-4 p-4">
                <SmallTextInput heading="Title" :value="form.title" @update:value="form.title=$event" />
                <SmallTextInput heading="Photographer" :value="form.photographer" @update:value="form.photographer=$event"/>
                <SmallTextInput heading="Location" :value="form.location" @update:value="form.location=$event"/>
                <LargeTextInput heading="Caption" :value="form.caption" @update:value="form.caption=$event"/>
                <DateInput heading="Date" :value="form.date" @update:value="form.date=$event"/>
                <SaveCancelForm 
                    class="col-span-3" 
                    :display="true" 
                    justifyOrientation="end" 
                    @onCancel="$emit('close')"
                    @onSave="onEdit"
                />
            </div>
        </div>        
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import DateInput from '@/components/inputs/DateInput'
import LargeTextInput from '@/components/inputs/LargeTextInput'
import SaveCancelForm from '@/components/forms/SaveCancelForm' 

export default {
    name: "MediaImageModalEdit",
    components: { SmallTextInput, DateInput, LargeTextInput, SaveCancelForm },
    props: {
        image: {
            type: Object,
            required: true
        } 
    },
    setup(props, { emit }) {
        const store = useStore()
        const form = reactive({
            caption: props.image.caption,
            date: props.image.date,
            location: props.image.location,
            photographer: props.image.photographer,
            title: props.image.title
        }) 

        return {
            form,
            modalImg: computed(() => {
                return props.image.image || props.image.croppedImage // Pick full size image if there is one
            }),
            onEdit() {
                store.dispatch('editUserMediaImage', {...form, uuid:props.image.uuid}) 
                emit('close')
            }
        }
    }
}
</script>

