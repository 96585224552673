<template>
  <div class="col-span-3">
    <label class="block text-sm font-medium text-gray-700 dark:text-white dark:bg-black">
      {{ heading }}
    </label>
    <div class="mt-1 mb-2 rounded-md sentence-case">
      <textarea v-if="counting" @keyup="counter" :placeholder="placeholder" :value="value" @input="$emit('update:value', $event.target.value)" :rows="rows" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-white dark:bg-primaryDark no-scroll-bar" />
      <textarea v-if="!counting" :placeholder="placeholder" :value="value" @input="$emit('update:value', $event.target.value)" :rows="rows" class="shadow-sm focus:ring-indigo-500  focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-white dark:bg-primaryDark no-scroll-bar"/>
    </div>
    <span class="text-sm dark:text-white" v-if="counting">Characters : <span class="font-bold ">{{ count }}</span></span>
    <div v-for="error in errors" :key="error.$uid">
      <p class="text-red-600">{{ error.$message }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "LargeTextInput",
  props: {
    heading: String,
    value: String,
    errors: Object,
    placeholder: String,
    counting: Boolean,
    rows: {
      type: String,
      default: "3"
    }
  },
  methods: {
    counter(){
      this.count = this.value.length
    },
  },
  data(){
    return{
      count: (this.$props.value) ? this.$props.value.length :0
    }
  }
}
</script>

<style scoped>

</style>