<template>
        <div class="relative grid grid-rows-6 grid-cols-1 h-full w-full bg-white shadow">
            <div class="relative row-span-4 col-span-1 flex justify-center bg-black">
                <!-- ARROWS -->
                <div v-if="images.length > 1" class="absolute w-full h-full flex items-center justify-between text-white">
                    <ChevronLeftIcon @click="movePictureLeft" class="w-10 relative cursor-pointer" />
                    <ChevronRightIcon @click="movePictureRight" class="w-10 relative cursor-pointer"/>
                </div>
                <img :src="modalImg" class="object-cover"/>
            </div>
            <div class="row-span-2 col-span-1 grid grid-cols-6 ">
                <div class="col-span-6 h-3/4">
                    <div class="flex justify-between">
                        <div class="flex items-center gap-2 p-2 ">
                            <CameraIcon class="w-5 " />
                            <p>{{imageInfo.photographer}}</p>
                        </div>
                        <!-- MENU -->
                        <Menu v-if="editMode" as="div" class="relative flex flex-col m-2 mr-4  items-end w-40">
                            <MenuButton class="">
                                <DotsHorizontalIcon class="w-8  cursor-pointer" />
                            </MenuButton>
                            <MenuItems class="absolute mt-8 w-full shadow border">
                                <MenuItem>
                                    <p @click="onEdit" class="cursor-pointer text-lg p-2" > Edit</p>
                                </MenuItem>
                                <MenuItem>
                                    <p @click="onDelete" class="cursor-pointer text-lg p-2" > Delete</p>
                                </MenuItem>
                            </MenuItems>
                          </Menu>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <h2 class="text-lg font-semibold">{{imageInfo.title}}</h2>
                        <p>{{imageInfo.caption}}</p>
                    </div>
                    <div class="h-full flex items-end gap-2 p-2">
                        <LocationMarkerIcon class="w-5 " />
                        <p class="">{{imageInfo.location}}</p>
                    </div>
                </div>
            </div>
        </div>        
</template>

<script>
/* eslint-disable vue/no-unused-components ,no-unused-vars */
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import Modal from '@/components/widgets/Modal'

import { ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from '@heroicons/vue/solid/esm'
import { CameraIcon, LocationMarkerIcon } from '@heroicons/vue/outline/esm'
export default {
    name: "MediaImageModalView",
    components: { Menu, MenuButton, MenuItems, MenuItem,
        DotsHorizontalIcon, ChevronLeftIcon, ChevronRightIcon,
        LocationMarkerIcon, CameraIcon, Modal, 
    },
    props: {
        images: {
            type: Array,
            required: true
        },
        curIdx: {
            type: Number,
            default: 0
        },
        modalActive: {
            type: Boolean
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const store = useStore()
        const idx = ref(JSON.parse(JSON.stringify(props.curIdx))) // Deepcopy

        watch(() => props.curIdx, val => { // Watch for changes from parent
            idx.value = val
        })

        function movePictureRight() {
            idx.value++;
            if (idx.value >= props.images.length) idx.value = 0;
        }
        function movePictureLeft() {
            idx.value--;
            if (idx.value < 0) idx.value = props.images.length - 1;
        }
        function onCycleImg(event) {
            switch (event.keyCode) {
                case 39:
                    movePictureRight();
                    break;
                case 37:
                    movePictureLeft();
                    break;
                default:
                    break;
            } 
        }

        onMounted(() => {
            document.addEventListener('keyup', onCycleImg)
        })

        onBeforeUnmount(() => {
            document.removeEventListener('keyup', onCycleImg)
        })

        const imageInfo = computed(() => props.images[idx.value])

        return {
            movePictureRight,
            movePictureLeft,
            imageInfo,
            modalImg: computed(() => {
                let img = props.images[idx.value]
                return img.image || img.croppedImage // Pick full size image if there is one
            }),
            onDelete() {
                store.dispatch("deleteMediaImage", imageInfo.value)
                emit("close")

            },
            onEdit() {
                emit("edit", imageInfo.value)
            },

        }
    }


}
</script>
